import React from 'react';
import {
  Link
} from 'react-router-dom';

class Bag extends React.Component {

  render() {
    var pictureUrl = this.props.picture?`/data/${this.props.picture}`:`/img/gift1.png`;

    return (
      <div className="col-md-4 col-lg-3">
        <div className="gift-box-singles" style={{backgroundImage: `url('${pictureUrl}')`}}>
          <Link to={`/bags/${this.props._id}`}>Kijk wat er inzit</Link>
          <div className="overlay-content">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src="img/gift-box.svg" alt="" />
              </div>
              <div className="flex-grow-1 ms-1">
                <h5>{this.props.name}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Bag;
