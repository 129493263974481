import React from 'react';

class SendYourOwn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bags: []
    }
  }

  componentDidMount() {

  }

  render() {


    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            Verstuur je eigen geschenzakje
          </div>
        </div>
      </div>
    );
  }
}

export default SendYourOwn;
