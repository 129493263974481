import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import LandingPage from './LandingPage';
import SendYourOwn from './SendYourOwn';
import AllBags from './AllBags';
import ViewBag from './ViewBag';
import Code from './Code';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lastUpdate: null
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <Router>
        <nav className="navbars">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <Link to="/" className="brand-nav"><img src="img/logo.svg" alt="" /></Link>
              </div>
              <div className="col">
                <div className="text-end">
                  <ul className="nav-menu d-flex align-items-center justify-content-end">
                    <li className="d-none d-lg-block">
                      <Link to="/bags">Bekijk alle geschenkzakjes</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>


        <Switch>
          <Route path="/bags/:bagId" component={ViewBag}/>
          <Route path="/bags" component={AllBags} />
          <Route path="/send" component={SendYourOwn} />
          <Route path="/:code" component={Code}/>
          <Route path="/" component={LandingPage} />
        </Switch>

        <footer className="footers">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">

              </div>
              <div className="col-md-6">
                <ul className="text-end footer-links">
                  <li>
                    <a href="mailto:info@kerstelfje.be">Vragen? Contacteer ons op info@kerstelfje.be</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>


      </Router>
    );
  }
}

export default App;

