import React from 'react';
import {
  Link
} from 'react-router-dom';
import Bag from './Bag';

class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bags: []
    }
  }

  componentDidMount() {
    this.fetchBags();
  }


  fetchBags() {
    fetch(`/api/bags?limit=4`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          bags: data.bags
        });
      });
  }


  render() {
    return (
      <div>
        <section className="banner-sec">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner-content-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="text">
                        <h2>Elk jaar rond december<br />
                          sturen Sam & Sofie 2 geschenkzakjes de wereld in. Volg hun reis op deze site.</h2>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-img-banner">
                        <img src="img/banner-img.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="recently-gift-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-md-start text-center">
                <h3>Recente geschenkzakjes</h3>
              </div>
              <div className="col-md-6 text-end d-none d-md-block">
                <Link to="/bags" className="links">Bekijk ze allemaal  <img src="img/Arrow.svg" alt="" /></Link>
              </div>
              <div className="col-12 pt-50 pb-20">
                <div className="row">
                  { this.state.bags.map(bag => <Bag key={bag._id} {...bag} />) }
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    );
  }
}

export default LandingPage;

