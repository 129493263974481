import React from 'react';
import Bag from './Bag';
import {
  Link
} from 'react-router-dom';

class AllBags extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bags: []
    }
  }

  componentDidMount() {
    this.fetchBags();
  }


  fetchBags() {
    fetch(`/api/bags`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          bags: data.bags
        });
      });
  }


  render() {
    return (
      <div>
        <section className="all-gifts-wrap pb-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="broadcrumb">
                  <Link to="/">Home</Link>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.64549 8.5061L1.3338 0.194423C1.05864 -0.071323 0.620169 -0.0636931 0.354422 0.211469C0.0951685 0.479894 0.0951685 0.905421 0.354422 1.17381L8.17642 8.99579L0.354422 16.8178C0.0840078 17.0882 0.0840078 17.5267 0.354422 17.7972C0.624917 18.0676 1.06335 18.0676 1.3338 17.7972L9.64549 9.48548C9.91591 9.21499 9.91591 8.77656 9.64549 8.5061Z" fill="#7E19FD" />
                  </svg>
                  <h3>Alle geschenkzakjes</h3>
                </div>
              </div>
              <div className="col-12 pt-50">
                <div className="row">
                  { this.state.bags.map(bag => <Bag key={bag._id} {...bag} />) }
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    );
  }
}

export default AllBags;
