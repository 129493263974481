import React from 'react';
import moment from 'moment'

class ViewBag extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bag: null,
      code: null,
      newElf: {
        name: '',
        city: '',
        tookOut: '',
        putIn: ''
      }
    }
  }

  componentDidMount() {
    var code = new URLSearchParams(window.location.search).get('code');
    this.fetchBag(this.props.match.params.bagId, code);
    this.setState({code: code});
  }

  fetchBag(bagId, code) {
    var url = `/api/bags/${bagId}`;
    if(code) url += `?code=${code}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          bag: data.bag
        });
      });
  }

  newElfChanged(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var newElf = this.state.newElf;
    newElf[name] = value;
    this.setState({newElf: newElf});
  }

  addElfClicked(event) {
    fetch(`/api/bags/${this.state.bag._id}/elves?code=${this.state.code}`, {
      method: "POST",
      body: JSON.stringify(this.state.newElf),
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      }
    })
    .then(res => res.json())
    .then(data => {
      var bag = this.state.bag;
      bag.elves.push(data.elf);
      this.setState({
        bag: bag
      });
    });
  }

  renderHeader(pictureUrl) {
    return(
      <div className="col-12">
        <div className="gift-bags-inside-bx">
          <div className="col-lg-6">
            <h2>Dit geschenkzakje is de wereld in gestuurd door <strong>{this.state.bag.createdBy}</strong></h2>
            <p>
              * Heb je liever je naam niet meer in de lijst? Stuur ons een mailtje op <a href="mailto:info@kerstelfje.be">info@kerstelfje.be</a>
            </p>
          </div>
        </div>{/*/.gift-bags-inside-bx*/}
        <div className="img-down-gift">
          <div className="col-lg-6 ms-auto">
            <div className="images" style={{backgroundImage: `url('${pictureUrl}')`}} />
          </div>
        </div>
      </div>
    );
  }

  renderHeaderWithEntryButton(pictureUrl) {
    return (
      <div className="col-12">
        <div className="gift-bags-inside-bx scan">
          <div className="col-lg-6">
            <h2>Wat leuk, je hebt een geschenkzakje gekregen!</h2>
            <div className="d-flex">
              <button type="button" className="btn btn-gra" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Laat ons weten wie je bent en wat je er mee gedaan hebt. We zijn benieuwd!
              </button>
              <img src="img/arrow-btn.png" alt="" />
            </div>
          </div>
        </div>{/*/.gift-bags-inside-bx*/}
        <div className="scan-text-below d-none d-lg-block">
          <p>* Heb je liever je naam niet meer in de lijst? Stuur ons een mailtje op <a href="mailto:info@kerstelfje.be">info@kerstelfje.be</a></p>
        </div>
        <div className="img-down-gift scan">
          <div className="col-lg-6 ms-auto">
            <div className="images" style={{backgroundImage: `url('${pictureUrl}')`}} />
          </div>
        </div>
        <div className="scan-text-below d-block d-lg-none">
          <p>* Heb je liever je naam niet meer in de lijst? Stuur ons een mailtje op <a href="mailto:info@kerstelfje.be">info@kerstelfje.be</a></p>
        </div>
      </div>
    );
  }

  renderModal(pictureUrl) {
    return (
      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row g-0">
                <div className="col-md-8">
                  <h1>Wat leuk, je hebt een geschenkzakje gekregen!</h1>
                  <p>Laat ons weten wie je bent en wat je er mee gedaan hebt. We zijn benieuwd!</p>
                </div>
                <div className="col-md-4">
                  <img src="/img/gift-send-upload.png" alt="" />
                </div>
              </div>
              <div className="modal-form-gift">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="single-form">
                        <label>Naam (of schuilnaam)</label>
                        <input type="text" className="form-control" name="name" value={this.state.newElf.name} onChange={(event) => this.newElfChanged(event)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-form">
                        <label>Vanwaar ben je (stad, gemeente)?</label>
                        <input type="text" className="form-control" name="city" value={this.state.newElf.city} onChange={(event) => this.newElfChanged(event)} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="single-form">
                        <label>Wat heb je uit het zakje gehaald?</label>
                        <input type="text" className="form-control" name="tookOut" value={this.state.newElf.tookOut} onChange={(event) => this.newElfChanged(event)} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="single-form">
                        <label>Wat heb je in het zakje gestoken?</label>
                        <input type="text" className="form-control" name="putIn" value={this.state.newElf.putIn} onChange={(event) => this.newElfChanged(event)} />
                      </div>
                    </div>
                    <div className="col-12 btn-grps">
                      <button type="button" className="btn gft" data-bs-dismiss="modal" onClick={(e) => this.addElfClicked(e)}>Verstuur</button>
                      <button type="button" className="btn close" data-bs-dismiss="modal">Sluiten</button>
                    </div>
                  </div>
                </form>
              </div>{/*/.modal-form-gift*/}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if(!this.state.bag) return '';

    var pictureUrl = this.state.bag.picture?`/data/${this.state.bag.picture}`:`/img/gift1.png`;
    moment.locale('nl');

    var elvesRendered = this.state.bag.elves.map(elf => {
      return (
        <tr key={elf._id}>
          <td>{moment(elf.timestamp).format('D MMMM YYYY')}</td>
          <td>{elf.name}</td>
          <td>{elf.city}</td>
          <td>{elf.tookOut}</td>
          <td>{elf.putIn}</td>
        </tr>
      );
    });

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="broadcrumb">
                <a href="/">Home</a>
                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.64549 8.5061L1.3338 0.194423C1.05864 -0.071323 0.620169 -0.0636931 0.354422 0.211469C0.0951685 0.479894 0.0951685 0.905421 0.354422 1.17381L8.17642 8.99579L0.354422 16.8178C0.0840078 17.0882 0.0840078 17.5267 0.354422 17.7972C0.624917 18.0676 1.06335 18.0676 1.3338 17.7972L9.64549 9.48548C9.91591 9.21499 9.91591 8.77656 9.64549 8.5061Z" fill="#7E19FD" />
                </svg>
                <a href="/bags">Alle geschenkzakjes</a>
                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.64549 8.5061L1.3338 0.194423C1.05864 -0.071323 0.620169 -0.0636931 0.354422 0.211469C0.0951685 0.479894 0.0951685 0.905421 0.354422 1.17381L8.17642 8.99579L0.354422 16.8178C0.0840078 17.0882 0.0840078 17.5267 0.354422 17.7972C0.624917 18.0676 1.06335 18.0676 1.3338 17.7972L9.64549 9.48548C9.91591 9.21499 9.91591 8.77656 9.64549 8.5061Z" fill="#7E19FD" />
                </svg>
                <h3>{this.state.bag.name}</h3>
              </div>{/*/.broadcrumb*/}
            </div>
          </div>
        </div>
        {/*gift-box-desc*/}
        <section className="gift-box-desc pt-50">
          <div className="container">
            <div className="row">
              {this.state.bag.validCode?this.renderHeaderWithEntryButton(pictureUrl):this.renderHeader(pictureUrl)}
              <div className="col-12 pt-100 pb-100">
                <div className="gift-box-information-table">
                  <table className="table  table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Wanneer</th>
                        <th scope="col">Naam</th>
                        <th scope="col">Plaats</th>
                        <th scope="col">Uitgehaald</th>
                        <th scope="col">Ingestoken</th>
                      </tr>
                    </thead>
                    <tbody>
                      {elvesRendered}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>{/*/.container*/}
        </section>
        {/*gift-box-desc*/}

        {this.renderModal()}
      </div>



    );
  }
}

export default ViewBag;
