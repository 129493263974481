import React from 'react';

class Code extends React.Component {

  componentDidMount() {
    var code = this.props.match.params.code;
    fetch(`/api/getbag?code=${code}`)
      .then((res) => res.json())
      .then((data) => {
        if(data.err) {
          this.props.history.push('/')
        }else{
          this.props.history.push(`/bags/${data.id}?code=${code}`)
        }
      });
  }

  render() {
    return '';
  }

}

export default Code;
